img.adaptive-image {
  max-width: 100%;
}

#comments {
  margin-top: 15px;
}

.indented {
  margin-left: 25px;
}

.comment-unpublished {
  background-color: #fff4f4;
}

.comment-preview {
  background-color: #ffffea;
}

.field .field-label {
  font-weight: bold;
}

.field-label-inline .field-label, .field-label-inline .field-items {
  float: left;
}

form .field-multiple-table {
  margin: 0;
}

form .field-multiple-table th.field-label {
  padding-left: 0;
}

form .field-multiple-table td.field-multiple-drag {
  width: 30px;
  padding-right: 0;
}

form .field-multiple-table td.field-multiple-drag a.tabledrag-handle {
  padding-right: .5em;
}

form .field-add-more-submit {
  margin: .5em 0 0;
}

.node-unpublished {
  background-color: #fff4f4;
}

.preview .node {
  background-color: #ffffea;
}

td.revision-current {
  background: #ffc;
}

.search-form {
  margin-bottom: 1em;
}

.search-form input {
  margin-top: 0;
  margin-bottom: 0;
}

.search-results {
  list-style: none;
}

.search-results p {
  margin-top: 0;
}

.search-results .title {
  font-size: 1.2em;
}

.search-results li {
  margin-bottom: 1em;
}

.search-results .search-snippet-info {
  padding-left: 1em;
}

.search-results .search-info {
  font-size: .85em;
}

.search-advanced .criterion {
  float: left;
  margin-right: 2em;
}

.search-advanced .action {
  float: left;
  clear: left;
}

#permissions td.module {
  font-weight: bold;
}

#permissions td.permission {
  padding-left: 1.5em;
}

#permissions tr.odd .form-item, #permissions tr.even .form-item {
  white-space: normal;
}

#user-admin-settings fieldset .fieldset-description {
  padding-bottom: .5em;
  font-size: .85em;
}

#user-admin-roles td.edit-name {
  clear: both;
}

#user-admin-roles .form-item-name {
  float: left;
  margin-right: 1em;
}

.password-strength {
  float: right;
  width: 17em;
  margin-top: 1.4em;
}

.password-strength-title {
  display: inline;
}

.password-strength-text {
  float: right;
  font-weight: bold;
}

.password-indicator {
  background-color: #c4c4c4;
  width: 100%;
  height: .3em;
}

.password-indicator div {
  background-color: #47c965;
  width: 0%;
  height: 100%;
}

input.password-confirm, input.password-field {
  width: 16em;
  margin-bottom: .4em;
}

div.password-confirm {
  float: right;
  visibility: hidden;
  width: 17em;
  margin-top: 1.5em;
}

div.form-item div.password-suggestions {
  border: 1px solid #b4b4b4;
  width: 38.5em;
  margin: .7em 0;
  padding: .2em .5em;
}

div.password-suggestions ul {
  margin-bottom: 0;
}

.confirm-parent, .password-parent {
  clear: left;
  width: 36.3em;
  margin: 0;
}

.profile {
  clear: both;
  margin: 1em 0;
}

.profile .user-picture {
  float: right;
  margin: 0 1em 1em 0;
}

.profile h3 {
  border-bottom: 1px solid #ccc;
}

.profile dl {
  margin: 0 0 1.5em;
}

.profile dt {
  margin: 0 0 .2em;
  font-weight: bold;
}

.profile dd {
  margin: 0 0 1em;
}

.views-exposed-form .views-exposed-widget {
  float: left;
  padding: .5em 1em 0 0;
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 1.6em;
}

.views-exposed-form .form-item, .views-exposed-form .form-submit {
  margin-top: 0;
  margin-bottom: 0;
}

.views-exposed-form label {
  font-weight: bold;
}

.views-exposed-widgets {
  margin-bottom: .5em;
}

.views-align-left {
  text-align: left;
}

.views-align-right {
  text-align: right;
}

.views-align-center {
  text-align: center;
}

.views-view-grid tbody {
  border-top: none;
}

.view .progress-disabled {
  float: none;
}

.rteindent1 {
  margin-left: 40px;
}

.rteindent2 {
  margin-left: 80px;
}

.rteindent3 {
  margin-left: 120px;
}

.rteindent4 {
  margin-left: 160px;
}

.rteleft {
  text-align: left;
}

.rteright {
  text-align: right;
}

.rtecenter {
  text-align: center;
}

.rtejustify {
  text-align: justify;
}

.ibimage_left {
  float: left;
}

.ibimage_right {
  float: right;
}
/*# sourceMappingURL=about.3a2b9de7.css.map */
